<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.update_expence") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submitTransaction(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('formCostTransactionUpdate')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="row">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <el-form
            ref="formCostTransactionUpdate"
            status-icon
            :model="form"
            :rules="rules"
            :label-position="'left'"
          >
            <el-col :span="10" class="mr-4">
              <el-form-item
                :label="columns.transactionable_id.title"
                prop="transactionable_id"
              >
                <el-select
                  v-model="form.transactionable_id"
                  :placeholder="columns.transactionable_id.title"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in costs"
                    :key="item.id + item.name"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                :label="columns.payment_type_id.title"
                prop="payment_type_id"
              >
                <el-select
                  v-model="form.payment_type_id"
                  :placeholder="columns.payment_type_id.title"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in payment_types"
                    :key="item.id + item.name"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="columns.price.title">
                <money
                  v-model="form.price"
                  v-bind="money"
                  class="el-input__inner"
                ></money>
              </el-form-item>

              <el-form-item :label="columns.date.title">
                <el-date-picker
                  v-model="form.date"
                  type="date"
                  style="width: 100%"
                  :placeholder="columns.date.title"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-button
                class="mt-4 my-4 py-2 px-4"
                @click="drawerCreate = true"
                icon="el-icon-circle-plus-outline"
              >
              </el-button>
              <el-form-item
                :label="columns.currency_id.title"
                prop="currency_id"
              >
                <el-select
                  v-model="form.currency_id"
                  :placeholder="columns.currency_id.title"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in currencies"
                    :key="item.id + item.name"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="columns.rate.title">
                <el-input-number
                  v-model="form.rate"
                  controls-position="right"
                  :min="1"
                  style="width: 60%"
                ></el-input-number>
              </el-form-item>

              <el-form-item :label="columns.comment.title" prop="comment">
                <el-input
                  type="textarea"
                  size="mini"
                  v-model="form.comment"
                  :placeholder="columns.comment.title"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form>
          <!-- <el-col :span="24">
                        <table>
                            <tbody>
                                <tr>
                                    <th scope="row">
                                        <el-button type="primary" round @click="submitTransaction(true)" size="small"> Сохранить
                                            и закрыть</el-button>
                                        <el-button round size="small" @click="resetForm('formCostTransactionUpdate')"> Отменить
                                        </el-button>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </el-col> -->
        </div>
      </div>
      <el-drawer
        :title="$t('message.new_type_expence')"
        :visible.sync="drawerCreate"
        size="60%"
        :append-to-body="true"
        :drawer="drawerCreate"
        @close="reloadIfChanged"
      >
        <div>
          <crm-create
            @c-close="reloadIfChanged('drawerCreate')"
            @selected_id="select"
          ></crm-create>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./crm-create-cost";
import form from "@/utils/mixins/form";
export default {
  mixins: [form],
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  components: {
    CrmCreate,
  },
  data() {
    return {
      drawerCreate: false,
    };
  },

  created() {},

  async mounted() {
    if (this.costs && this.costs.length === 0) await this.loadCosts();
    if (this.payment_types && this.payment_types.length === 0)
      await this.loadPaymentTypes();
    if (this.currencies && this.currencies.length === 0)
      await this.loadCurrencies();
  },

  computed: {
    ...mapGetters({
      model: "costTransactions/model",
      rules: "costTransactions/rules",
      columns: "costTransactions/columns",
      costs: "costs/list",
      payment_types: "paymentTypes/list",
      currencies: "currencies/list",
      money: "money",
    }),
  },

  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      save: "costTransactions/update",
      loadCosts: "costs/index",
      loadPaymentTypes: "paymentTypes/index",
      loadCurrencies: "currencies/index",
    }),
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    submitTransaction(close = true) {
      this.$refs["formCostTransactionUpdate"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.resetForm("formCostTransactionUpdate");
              this.parent().listChanged();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    reloadIfChanged(modal) {
      this[modal] = false;
      if (this.reloadList === true) {
        this.fetchData();
        this.afterFetchData();
      }
    },
    select(res) {
      this.loadCosts().then((a) => {
        this.form.transactionable_id = res.data.cost.id;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>
