<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.new_expence") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submitTransaction(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('formCostTransaction')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="row">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <el-form
            ref="formCostTransaction"
            status-icon
            :model="form"
            :rules="rules"
            :label-position="'left'"
          >
            <el-col :span="12" class="mr-4">
              <el-form-item
                :label="columns.transactionable_id.title"
                prop="transactionable_id"
              >
                <el-select
                  v-model="form.transactionable_id"
                  :placeholder="columns.transactionable_id.title"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in costs"
                    :key="item.id + item.name"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                :label="columns.payment_type_id.title"
                prop="payment_type_id"
              >
                <el-select
                  v-model="form.payment_type_id"
                  :placeholder="columns.payment_type_id.title"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in payment_types"
                    :key="item.id + item.name"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="columns.price.title">
                <div class="el-input el-input--small">
                  <money
                    v-model="form.price"
                    v-bind="money"
                    class="el-input__inner"
                  ></money>
                </div>
              </el-form-item>

              <el-form-item :label="columns.date.title">
                <el-date-picker
                  v-model="form.date"
                  type="date"
                  :placeholder="columns.date.title"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="columns.currency_id.title"
                prop="currency_id"
              >
                <el-select
                  v-model="form.currency_id"
                  :placeholder="columns.currency_id.title"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in currencies"
                    :key="item.id + item.name"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="columns.rate.title">
                <el-input-number
                  v-model="form.rate"
                  controls-position="right"
                  :min="1"
                ></el-input-number>
              </el-form-item>

              <el-form-item :label="columns.comment.title" prop="comment">
                <el-input
                  type="textarea"
                  size="mini"
                  v-model="form.comment"
                  :placeholder="columns.comment.title"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>

            <el-button
              class="mt-4 py-2 px-4"
              @click="drawerCreate = true"
              icon="el-icon-circle-plus-outline"
            >
            </el-button>
          </el-form>
        </div>
      </div>
      <el-drawer
        :title="$t('message.new_type_expence')"
        :visible.sync="drawerCreate"
        size="60%"
        :append-to-body="true"
        :drawer="drawerCreate"
        @close="reloadIfChanged"
      >
        <div>
          <crm-create
            @c-close="reloadIfChanged('drawerCreate')"
            @selected_id="select"
          ></crm-create>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./crm-create-cost";
import form from "@/utils/mixins/form";
export default {
  mixins: [form],
  components: {
    CrmCreate,
  },
  data() {
    return {
      drawerCreate: false,
    };
  },
  created() {
    this.form.date = new Date();
  },

  async mounted() {
    if (this.costs && this.costs.length === 0) await this.loadCosts();
    if (this.payment_types && this.payment_types.length === 0)
      await this.loadPaymentTypes();
    if (this.currencies && this.currencies.length === 0)
      await this.loadCurrencies();
  },

  computed: {
    ...mapGetters({
      model: "costTransactions/model",
      rules: "costTransactions/rules",
      columns: "costTransactions/columns",
      costs: "costs/list",
      payment_types: "paymentTypes/list",
      currencies: "currencies/list",
      money: "money",
    }),
  },

  watch: {
    "form.currency_id": {
      handler: function (new_value, old_value) {
        let currency = this.currencies.filter((currencies) => {
          return this.form.currency_id === currencies.id;
        });
        if (currency.length) {
          this.form.rate = parseFloat(currency[0].rate);
        } else {
          this.form.rate = 1;
        }
      },
      deep: true,
    },

    "form.transactionable_id": {
      handler: function (new_value, old_value) {
        let transactionable = this.costs.filter((costs) => {
          return this.form.transactionable_id === costs.id;
        });
        if (transactionable.length) {
          this.form.price = parseFloat(transactionable[0].amount);
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      save: "costTransactions/store",
      loadCosts: "costs/index",
      loadPaymentTypes: "paymentTypes/index",
      loadCurrencies: "currencies/index",
    }),

    submitTransaction(close = true) {
      this.$refs["formCostTransaction"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.form.date = new Date();
              this.resetForm("formCostTransaction");
              this.parent().listChanged();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    reloadIfChanged(modal) {
      this[modal] = false;
      if (this.reloadList === true) {
        this.fetchData();
        this.afterFetchData();
      }
    },
    select(res) {
      this.loadCosts().then((a) => {
        this.form.transactionable_id = res.data.cost.id;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerCreate" });
    },
  },
};
</script>
